/** Cookie popup **/
.ckpopup-block{
    & .ckpopup-container{
        background: rgba(0, 0, 0, 0.9);
        color: white;
        padding: 15px 30px;
        font-size: 12px;
        line-height: 1.2em;
        text-align: center;
        display: none;
        z-index: 99999;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        & .ckpopup-close{
            font-size: 16px;
            font-weight: 100;
            line-height: 1;
            color: white;
            filter: alpha(opacity=20);
            position: absolute;
            top: 50%;
            right: 0;
            .translateY(-50%);
            padding: 5px 10px;
            & .fa{
                color: white;
            }
            &:hover{

            }
        }
        & .ckpopup-text{
            font-size: 12px;
            color: white;
            & .ckpopup-button{
                color: white;
                text-decoration: none;
                border-bottom: 2px solid white;
                -webkit-transition: all 0.3s;
                -moz-transition: all 0.3s;
                -ms-transition: all 0.3s;
                -o-transition: all 0.3s;
                transition: all 0.3s;
                &:hover{
                    border-bottom-color: transparent;
                }
            }
        }
    }
}