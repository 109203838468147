/**
 * Generic mixins
 */
.init(@font-family){
	* {
		padding: 0px;
		margin: 0px;
		box-sizing: border-box;
		font-family: @font-family;
	}
}

/**
 * Font mixins
 */
.font(@font-family, @font-size, @font-weight : 400, @line-height: normal, @letter-spacing: normal){
	letter-spacing: @letter-spacing;
	   font-family: @font-family;
	   font-weight: @font-weight;
	   line-height: @line-height;
		 font-size: @font-size;
}

.font-details(@font-weight: 400, @line-height: normal, @letter-spacing: normal){
	letter-spacing: @letter-spacing;
	   font-weight: @font-weight;
	   line-height: @line-height;
}


/**
 * Transformations mixins
 */
.backface-visibility(@visibility){
	-webkit-backface-visibility: @visibility;
			backface-visibility: @visibility;
}

.transform3d(...){
	// Transform the element
	-webkit-transform: @arguments;
		-ms-transform: @arguments;
			transform: @arguments;
}

.transform(...){
	// Transform the element
	-webkit-transform: @arguments;
		-ms-transform: @arguments;
			transform: @arguments;

	// Hide the backface of the element
	//.backface-visibility(hidden);
}

.translate(@x-amount, @y-amount){
	// Transform the element
	.transform(translate(@x-amount, @y-amount));
}

.translateX(@amount){
	// Translate the element
	.transform(translateX(@amount));
}

.translateY(@amount){
	// Translate the element
	.transform(translateY(@amount));
}

.translateZ(@amount){
	// Translate the element
	.transform(translateZ(@amount));
}

.scale(@amount){
	// Scale the element
	.transform(scale(@amount));
}

.rotate(@amount){
	// Rotate the element
	.transform(rotate(@amount));
}

.rotateX(@amount){
	// Rotate the element
	.transform(rotateX(@amount));
}

.rotateY(@amount){
	// Rotate the element
	.transform(rotateY(@amount));
}

.rotateZ(@amount){
	// Rotate the element
	.transform(rotateZ(@amount));
}

.skew(@x-amount, @y-amount){
	// Skew the element
	.transform(skew(@x-amount, @y-amount));
}

.skewX(@amount){
	// Skew the element
	.transform(skewX(@amount));
}

.skewY(@amount){
	// Skew the element
	.transform(skewY(@amount));
}


/**
 * Flex Mixins
 */
.flex-container(@flow: row, @wrap: nowrap){
	display: -webkit-box;
	display: -webkit-flex;
	 display: -ms-flexbox;
		display: -moz-box;
			display: flex;

	-webkit-flex-flow: @flow @wrap;
			flex-flow: @flow @wrap;
}

.flex-wrap(@wrap){
	-webkit-flex-wrap: @wrap;
		-ms-flex-wrap: @wrap;
			flex-wrap: @wrap;
}

.flex-order(@order){
	-webkit-box-ordinal-group: @order;
		-ms-box-ordinal-group: @order;
			   -ms-flex-order: @order;
				-webkit-order: @order;
				   		order: @order;
}

.flex(...){
	-webkit-box-flex: @arguments;
	   -moz-box-flex: @arguments;
	    -webkit-flex: @arguments;
			-ms-flex: @arguments;
				flex: @arguments;
}

.align-items(@param){
	-webkit-align-items: @param;
			align-items: @param;
}

.justify-content(@param){
	-webkit-justify-content: @param;
			justify-content: @param;
}


/**
 *  Filter Mixins
 */
.filter(...){
	-webkit-filter: @arguments;
			filter: @arguments;
}

/**
 *  Animation Mixin
 */
.keyframes(@name, @obj){
	@-webkit-keyframes @name{ @obj(); }
	@keyframes @name{ @obj(); }
}

.animation(...){
	-webkit-animation: @arguments;
	animation: @arguments;
}

/**
 * Placeholder mixin
 */
.placeholder(@input){
	&::-webkit-input-placeholder{
		@input();
	}
	&:-moz-placeholder{
		@input();
	}
	&:-ms-input-placeholder{
		@input();
	}
}


/**
 * Material icons mixin
 */
.material-icon(@size: 24px){
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: @size;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}
// Init
.init('Lato');
