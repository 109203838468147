.file-upload {
    & .file-upload-wrapper {
        & .file-uploader {
            & .file-container {
                position: relative;
                border: 1px solid @album-border-grey;
                .flex-container();
                .align-items(center);
                .justify-content(space-between);
                //max-width: 300px;
                cursor: pointer;
                padding: 3px 5px;
                max-width: 100%;
                width: 100%;
                & .filename {
                    font-size: 15px;
                    line-height: 32px;
                    letter-spacing: .1em;
                    padding-bottom: 0;
                    padding-left: 15px;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                }
                & .action {
                    .flex-container();
                    .align-items(center);
                    .justify-content(center);
                    &.upload {
                        .icon {
                            color: black;
                        }
                    }
                    &.delete {
                        .icon {
                            color: @nice-red;
                        }
                    }
                }
            }
        }
        & .file-upload-field {
            &.hide {
                display: none;
            }
        }
    }
}