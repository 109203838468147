
h1{
	font-size: 40px;
	line-height: 42px;
	letter-spacing: 0.075em;
	padding-bottom: 13px;
}
h2{
    font-size: 50px;
}
h3{
	font-size: 28px;
	line-height: 30px;
	letter-spacing: 0.075em;
	padding-bottom: 8px;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    word-wrap: break-word;
}
h4{
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.04em;
	padding-bottom: 8px;
}
p{
	font-size: 15px;
	line-height: 22px;
	letter-spacing: 0.06em;
	padding-bottom: 25px;
}
ul{
	padding-left: 15px;
	padding-bottom: 25px;

	& li{
		padding-bottom: 5px;
	}
}
a{
	color: @brand-navy;
	text-decoration: none;
}

.centered{
	text-align: center;
}

body{
	background-color: #eceff1;
	&.fixed{
		overflow: hidden;
	}
	&.internal{
		overflow: hidden;
	}
}

input, select {
    border-radius: 0;
    color: #333;
    border: 1px solid #cdcdcd;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: #ffffff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select {
    background-image: url(../../../images/arrow.png);
    background-position: right top;
    background-repeat: no-repeat;
}

//@-webkit-keyframes spinner-animation{
//    0%{
//        -webkit-transform:rotate(0deg);
//        -ms-transform:rotate(0deg);
//        transform:rotate(0deg);
//    }
//    100%{
//        -webkit-transform:rotate(360deg);
//        -ms-transform:rotate(360deg);
//        transform:rotate(360deg);
//    }
//}
//@keyframes spinner-animation{
//    0%{
//        -webkit-transform:rotate(0deg);
//        -ms-transform:rotate(0deg);
//        transform:rotate(0deg);
//    }
//    100%{
//        -webkit-transform:rotate(360deg);
//        -ms-transform:rotate(360deg);
//        transform:rotate(360deg);
//    }
//}
//.keyframes(spinner-animation, {
//	0%{
//		.rotate(0deg);
//	}
//	100%{
//		.rotate(360deg);
//	}
//});

.underline {
    text-decoration: underline;
}

.spinner{
	width: 50px;
	height: 50px;
	border: 5px solid fadeout(@nice-blue, 60%);
	border-top: 6px solid @nice-blue;
	border-radius: 50%;
	.backface-visibility(hidden);
    //-webkit-animation: spinner-animation 1s infinite linear;
    //animation: spinner-animation 1s infinite linear;
	&.small{
		width: 35px;
		height: 35px;
		border-width: 3px;
		border-top-width: 4px;
	}
	&.xsmall{
		width: 20px;
		height: 20px;
		border-width: 2px;
		border-top-width: 3px;
	}
}
.spinner-container{
	transition: all 0.2s;
}

.button{
	padding: 20px 70px;
	border: 0px;
	outline: none;
	cursor: pointer;
	position: relative;
	text-decoration: none;
	background-color: #e5e5e5;
	color: #8a8a8a;
	text-align: center;
	display: inline-block;
	line-height: 18px;
	font-size: 15px;
	letter-spacing: 0.03em;
	transition: all 0.4s;

	& .text{
		transition: all 0.3s;
	}
	& .spinner-container{
		position: absolute;
		top: 50%;
		right: 50%;
		.translate(50%, -100%);
		opacity: 0;
		transition: all 0.4s;
	}
	& .tooltip{
		position: absolute;
		top: -50px;
		left: 50%;
		.translate(-50%, 30px);
		background-color: @brand-light-navy;
		padding: 10px;
		transition: all 0.4s;
		opacity: 0;
		min-width: 200px;
	}
	&.small{
		padding: 15px 50px;
	}
	&.xsmall{
		padding: 15px 30px;
	}
	&.green{
		background-color: @brand-green;
		color: #fff;

		& .spinner-container{
			& .spinner{
				border-color: fadeout(#fff, 60%);
				border-top-color: #fff;
			}
		}
		&:hover{
			background-color: lighten(@brand-green, 5%);
		}
	}
	&.red{
		background-color: @nice-red;
		color: #fff;

		&:hover{
			background-color: darken(@nice-red, 5%);
		}
	}
	&.navy{
		background-color: @brand-navy;
		color: #fff;

		&:hover{
			background-color: lighten(@brand-navy, 5%);
		}
	}
	&.light-navy{
		background-color: @brand-light-navy;
		color: #fff;

		&:hover{
			background-color: lighten(@brand-light-navy, 5%);
		}
	}
	&.icon{
		& .icon{
			transition: all 0.15s ease-out;
			.backface-visibility(hidden);
		}
		& .text{
			position: absolute;
			top: 50%;
			left: 50%;
			.translate(-50%, -50%);
			opacity: 0;
			transition: all 0.35s;
			.backface-visibility(hidden);
		}
		&:hover{
			& .icon{
				opacity: 0;
			}
			& .text{
				opacity: 1;
			}
		}
	}
	&.active, &.activate{
		& .text{
			.translateY(10px);
			opacity: 0;
		}
		& .spinner-container{
			.translate(50%, -50%);
			opacity: 1;
		}
	}
    &.red{
        & .text{
            color: #fff;
        }
        & .spinner-container{
            & .spinner{
                border-color: #ffffff;
                border-top-color: #ffffff;
            }
        }
    }
	&:hover {
		background-color: darken(#e5e5e5, 5%);

		&.has-tooltip{
			& .tooltip {
				.translate(-50%, 0px);
				opacity: 1;
			}
		}
	}
}

.buttons-container{
	.flex-container();
	.align-items(flex-end);

	&.right{
		.justify-content(flex-end);

		& .button{
			margin-right: 5px;

			&:last-child{
				margin-right: 0px;
			}
		}
	}
}

.notifications-container{
	padding-bottom: 20px;

	& .notification{
		padding: 10px 25px 10px 38px;
		border-radius: 5px;
		position: relative;
		background-color: #fdfdfd;
		margin-bottom: 10px;
		.translateY(-15px);
		opacity: 0;
		transition: opacity 0.4s, transform 0.4s;

		& p{
			padding-bottom: 0px;
		}
		& .close-notification{
			position: absolute;
			top: 50%;
			right: 5px;
			.translateY(-50%);
			cursor: pointer;
		}
		&.show{
			opacity: 1;
			.translateY(0px);
		}
		&.hide{
			opacity: 0;
			.translateY(-15px);
		}
		&:after{
			font-size: 24px;
			position: absolute;
			top: 50%;
			left: 19px;
		}
	}
	&.fixed{
		position: fixed;
		top: 95px;
		left: 50%;
		.translateX(-50%);
		//width: 500px;
        width: 50vw;
		z-index: 1001;
		padding-bottom: 0px;
	}
}

.notification{
    max-width: 1026px;
    margin: 0px auto;
    //width: 70vw;
    width: 75%;
    position: relative;
    padding: 20px 5px 20px 80px;
    color: #fff;
    box-shadow: 0px 0px 5px #888;
    background-color: #fff;

    & .title{
        font-size: 25px;
        line-height: 17px;
        font-weight: 700;
        padding-bottom: 13px;
    }
    & p{
        font-size: 16px;
        line-height: 18px;
        padding-bottom: 5px;
    }
    & a{
        color: #efefef;
    }
    &.success{
        background-color: @nice-green;

        &:after{
            content: '\E5CA';
            font-weight: 700;
        }
    }
    &.info{
        background-color: @nice-blue;

        &:after{
            content: '\E88E';

        }
    }
    &.warning{
        background-color: #ffb238;

        &:after{
            content: '\E002';
        }
    }
    &.error{
        background-color: @nice-red;

        &:after{
            content: '\E000';

        }
    }
    &:after{
        font-weight: 300;
        .material-icon(40px);
        position: absolute;
        top: 50%;
        left: 40px;
        .translate(-50%, -50%);
    }
}

.keyframes(refresh-animation, {
	0%{
		.rotate(0deg);
	}
	100%{
		.rotate(360deg);
	}
});

.icon-notification{
	text-align: center;
	color: #c7c7c7;
	transition: opacity 0.4s, transform 0.4s;
	overflow: hidden;

	& .icon{
		font-size:80px;
	}
	& .content {
		padding: 20px 0px;

		& h1 {
			font-size: 17px;
			line-height: 20px;
			padding-bottom: 5px;
            @media only screen and (max-width: 1366px) {
                font-size: 17px !important;
            }
		}
		& a{
			color: #a9a9a9;
		}
	}
	&.full-width{
		width: 100%;
	}
	&.padded{
		padding-top: 100px;
	}
	&.padded-top-small{
		padding-top: 50px;
	}
	&.hide{
		opacity: 0;
		.translateY(50px);
	}
}

.animation-wrapper{
	&.hide{
		display: none;
	}
}

.tip-action{
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 15px;
	text-align: center;
	border-radius: 10px;
	cursor: pointer;
	font-style: normal;

	& span.tip-holder{
		opacity: 0;
		visibility: hidden;
		max-width: 250px;
		background-color: #1b282e;
		color: #ffffff;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		position: absolute;
		bottom: 200%;
		left: 50%;
		.translateX(-50%);
		transition: all 0.3s;
		padding: 10px;
		text-align: left;
		cursor: auto;
		&::after{
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -10px;
			border-width: 10px;
			border-style: solid;
			border-color: #1b282e transparent transparent transparent;
		}
		& *{
			font-size: 12px;
			color: #ffffff;
		}
	}
	&.red{
		& .tip-holder {
			background-color: @nice-red;
			color: #fff;

			&::after{
				border-top-color: @nice-red;
			}
		}
	}
	&.green{
		& .tip-holder {
			background-color: @brand-green;
			color: #fff;

			&::after{
				border-top-color: @brand-green;
			}
		}
	}
	&:hover{
		& span.tip-holder{
			opacity:1;
			visibility: visible;
			z-index: 100;
		}
	}
}

.page-transition-loop(@counter) when (@counter > 0){
	.page-transition-loop((@counter - 1));
	&:nth-child(@{counter}){
		transition: all 0.4s (@counter * 0.1s);
	}
}

.page{
	display: none;

	& .flex-wrapper, & .animation-wrapper{
		.flex-container(row, wrap);
		.justify-content(space-around);

		& .user-box{
			opacity: 0;
			.translateY(50px);
			.page-transition-loop(6);
		}
	}
	&.show{
		display: block;

		& .flex-wrapper, & .animation-wrapper{
			& .user-box{
				opacity: 1;
				.translateY(0px);
			}
		}
	}
	&.active{
		& .flex-wrapper, & .animation-wrapper{
			& .user-box{
				opacity: 1;
				.translateY(0px);
			}
		}
	}
}

.see-more {
	position: relative;

	& .button {
        background-color: @brand-green;
        color: #ffffff;
		& .text{
			transition: all 0.3s;
		}
		&.more {
			width: 100%;
		}
	}
	& .spinner-container{
		position: absolute;
		top: 50%;
		left: 50%;
		.translate(-50%, -70%);
		opacity: 0;
        & .spinner{
            border-top-color: #fbfbfb;
        }
	}
	& .results-text{
		position: absolute;
		top: 50%;
		left: 50%;
		.translate(-50%, -50%);
		margin: 0px;
		padding: 0px;
		color: #fff;
		cursor: pointer;
		opacity: 0;
	}
	&.loading{
		& .button{
			& .text{
				opacity: 0;
			}
		}
		& .spinner-container {
			.translate(-50%, -50%);
			opacity: 1;
		}
	}
	&.error{
		& .button {
			background-color: @nice-red;

			& .text {
				opacity: 0;
			}
		}
		& .results-text{
			opacity: 1;
		}
		&:after{
			content: '\E002';
			.material-icon(24px);
			position: absolute;
			top: 50%;
			left: 20px;
			color: #fff;
			.translateY(-50%);
		}
	}
}
.see-more-container{
    &.hidden{
        display: none;
    }
}
&.hidden{
	display: none;
}

.horizontal-rule {
    width: 100%;
    border-top: 1px solid @album-border-grey;
    margin-bottom: 30px;
}
.vertical-rule {
    width: 0px;
    border-right: 1px solid @album-border-grey;
}

iframe[name="google_conversion_frame"]{
    position:fixed;
}

@media screen and (max-width: 500px) {
	.button {
		& .tooltip {
			display: none;
		}
	}
}