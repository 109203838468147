.row{
    & table.sort{
        & thead{
            & th.sortable{
                cursor: pointer;
                &::after{
                    content: "\f0dc";
                    font-family: Font Awesome\ 5 Free;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    font-weight: 900;
                    padding-left: 5px;
                }
            }
        }
    }
    & #memberships-totals{
        & .animation-wrapper{
            display: block !important;
        }
        & p{
            padding: 5px 0;
        }
    }
}