/***
 * Nice colors
 */
/**
 *	Brand colors
 */
/**
 *	Other brand colorss
 */
/**
 *	Other colors
 */
/**
 * Generic mixins
 */
/**
 * Font mixins
 */
/**
 * Transformations mixins
 */
/**
 * Flex Mixins
 */
/**
 *  Filter Mixins
 */
/**
 *  Animation Mixin
 */
/**
 * Placeholder mixin
 */
/**
 * Material icons mixin
 */
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Lato';
}
h1 {
  font-size: 40px;
  line-height: 42px;
  letter-spacing: 0.075em;
  padding-bottom: 13px;
}
h2 {
  font-size: 50px;
}
h3 {
  font-size: 28px;
  line-height: 30px;
  letter-spacing: 0.075em;
  padding-bottom: 8px;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
}
h4 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.04em;
  padding-bottom: 8px;
}
p {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.06em;
  padding-bottom: 25px;
}
ul {
  padding-left: 15px;
  padding-bottom: 25px;
}
ul li {
  padding-bottom: 5px;
}
a {
  color: #1b282e;
  text-decoration: none;
}
.centered {
  text-align: center;
}
body {
  background-color: #eceff1;
}
body.fixed {
  overflow: hidden;
}
body.internal {
  overflow: hidden;
}
input,
select {
  border-radius: 0;
  color: #333;
  border: 1px solid #cdcdcd;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select {
  background-image: url(../../../images/arrow.png);
  background-position: right top;
  background-repeat: no-repeat;
}
.underline {
  text-decoration: underline;
}
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(52, 152, 219, 0.4);
  border-top: 6px solid #3498db;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.spinner.small {
  width: 35px;
  height: 35px;
  border-width: 3px;
  border-top-width: 4px;
}
.spinner.xsmall {
  width: 20px;
  height: 20px;
  border-width: 2px;
  border-top-width: 3px;
}
.spinner-container {
  transition: all 0.2s;
}
.button {
  padding: 20px 70px;
  border: 0px;
  outline: none;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  background-color: #e5e5e5;
  color: #8a8a8a;
  text-align: center;
  display: inline-block;
  line-height: 18px;
  font-size: 15px;
  letter-spacing: 0.03em;
  transition: all 0.4s;
}
.button .text {
  transition: all 0.3s;
}
.button .spinner-container {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -100%);
  -ms-transform: translate(50%, -100%);
  transform: translate(50%, -100%);
  opacity: 0;
  transition: all 0.4s;
}
.button .tooltip {
  position: absolute;
  top: -50px;
  left: 50%;
  -webkit-transform: translate(-50%, 30px);
  -ms-transform: translate(-50%, 30px);
  transform: translate(-50%, 30px);
  background-color: #2e414a;
  padding: 10px;
  transition: all 0.4s;
  opacity: 0;
  min-width: 200px;
}
.button.small {
  padding: 15px 50px;
}
.button.xsmall {
  padding: 15px 30px;
}
.button.green {
  background-color: #6ddb2c;
  color: #fff;
}
.button.green .spinner-container .spinner {
  border-color: rgba(255, 255, 255, 0.4);
  border-top-color: #fff;
}
.button.green:hover {
  background-color: #7cdf42;
}
.button.red {
  background-color: #e74c3c;
  color: #fff;
}
.button.red:hover {
  background-color: #e43725;
}
.button.navy {
  background-color: #1b282e;
  color: #fff;
}
.button.navy:hover {
  background-color: #24363e;
}
.button.light-navy {
  background-color: #2e414a;
  color: #fff;
}
.button.light-navy:hover {
  background-color: #384f5a;
}
.button.icon .icon {
  transition: all 0.15s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.button.icon .text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.35s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.button.icon:hover .icon {
  opacity: 0;
}
.button.icon:hover .text {
  opacity: 1;
}
.button.active .text,
.button.activate .text {
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}
.button.active .spinner-container,
.button.activate .spinner-container {
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  opacity: 1;
}
.button.red .text {
  color: #fff;
}
.button.red .spinner-container .spinner {
  border-color: #ffffff;
  border-top-color: #ffffff;
}
.button:hover {
  background-color: #d8d8d8;
}
.button:hover.has-tooltip .tooltip {
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  opacity: 1;
}
.buttons-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.buttons-container.right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.buttons-container.right .button {
  margin-right: 5px;
}
.buttons-container.right .button:last-child {
  margin-right: 0px;
}
.notifications-container {
  padding-bottom: 20px;
}
.notifications-container .notification {
  padding: 10px 25px 10px 38px;
  border-radius: 5px;
  position: relative;
  background-color: #fdfdfd;
  margin-bottom: 10px;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
  opacity: 0;
  transition: opacity 0.4s, transform 0.4s;
}
.notifications-container .notification p {
  padding-bottom: 0px;
}
.notifications-container .notification .close-notification {
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.notifications-container .notification.show {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.notifications-container .notification.hide {
  opacity: 0;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}
.notifications-container .notification:after {
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 19px;
}
.notifications-container.fixed {
  position: fixed;
  top: 95px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50vw;
  z-index: 1001;
  padding-bottom: 0px;
}
.notification {
  max-width: 1026px;
  margin: 0px auto;
  width: 75%;
  position: relative;
  padding: 20px 5px 20px 80px;
  color: #fff;
  box-shadow: 0px 0px 5px #888;
  background-color: #fff;
}
.notification .title {
  font-size: 25px;
  line-height: 17px;
  font-weight: 700;
  padding-bottom: 13px;
}
.notification p {
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 5px;
}
.notification a {
  color: #efefef;
}
.notification.success {
  background-color: #2ecc71;
}
.notification.success:after {
  content: '\E5CA';
  font-weight: 700;
}
.notification.info {
  background-color: #3498db;
}
.notification.info:after {
  content: '\E88E';
}
.notification.warning {
  background-color: #ffb238;
}
.notification.warning:after {
  content: '\E002';
}
.notification.error {
  background-color: #e74c3c;
}
.notification.error:after {
  content: '\E000';
}
.notification:after {
  font-weight: 300;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 40px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  position: absolute;
  top: 50%;
  left: 40px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@-webkit-keyframes refresh-animation {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes refresh-animation {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.icon-notification {
  text-align: center;
  color: #c7c7c7;
  transition: opacity 0.4s, transform 0.4s;
  overflow: hidden;
}
.icon-notification .icon {
  font-size: 80px;
}
.icon-notification .content {
  padding: 20px 0px;
}
.icon-notification .content h1 {
  font-size: 17px;
  line-height: 20px;
  padding-bottom: 5px;
}
@media only screen and (max-width: 1366px) {
  .icon-notification .content h1 {
    font-size: 17px !important;
  }
}
.icon-notification .content a {
  color: #a9a9a9;
}
.icon-notification.full-width {
  width: 100%;
}
.icon-notification.padded {
  padding-top: 100px;
}
.icon-notification.padded-top-small {
  padding-top: 50px;
}
.icon-notification.hide {
  opacity: 0;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}
.animation-wrapper.hide {
  display: none;
}
.tip-action {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  font-style: normal;
}
.tip-action span.tip-holder {
  opacity: 0;
  visibility: hidden;
  max-width: 250px;
  background-color: #1b282e;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  bottom: 200%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  transition: all 0.3s;
  padding: 10px;
  text-align: left;
  cursor: auto;
}
.tip-action span.tip-holder::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: #1b282e transparent transparent transparent;
}
.tip-action span.tip-holder * {
  font-size: 12px;
  color: #ffffff;
}
.tip-action.red .tip-holder {
  background-color: #e74c3c;
  color: #fff;
}
.tip-action.red .tip-holder::after {
  border-top-color: #e74c3c;
}
.tip-action.green .tip-holder {
  background-color: #6ddb2c;
  color: #fff;
}
.tip-action.green .tip-holder::after {
  border-top-color: #6ddb2c;
}
.tip-action:hover span.tip-holder {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}
.page {
  display: none;
}
.page .flex-wrapper,
.page .animation-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.page .flex-wrapper .user-box,
.page .animation-wrapper .user-box {
  opacity: 0;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}
.page .flex-wrapper .user-box:nth-child(1),
.page .animation-wrapper .user-box:nth-child(1) {
  transition: all 0.4s 0.1s;
}
.page .flex-wrapper .user-box:nth-child(2),
.page .animation-wrapper .user-box:nth-child(2) {
  transition: all 0.4s 0.2s;
}
.page .flex-wrapper .user-box:nth-child(3),
.page .animation-wrapper .user-box:nth-child(3) {
  transition: all 0.4s 0.3s;
}
.page .flex-wrapper .user-box:nth-child(4),
.page .animation-wrapper .user-box:nth-child(4) {
  transition: all 0.4s 0.4s;
}
.page .flex-wrapper .user-box:nth-child(5),
.page .animation-wrapper .user-box:nth-child(5) {
  transition: all 0.4s 0.5s;
}
.page .flex-wrapper .user-box:nth-child(6),
.page .animation-wrapper .user-box:nth-child(6) {
  transition: all 0.4s 0.6s;
}
.page.show {
  display: block;
}
.page.show .flex-wrapper .user-box,
.page.show .animation-wrapper .user-box {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.page.active .flex-wrapper .user-box,
.page.active .animation-wrapper .user-box {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.see-more {
  position: relative;
}
.see-more .button {
  background-color: #6ddb2c;
  color: #ffffff;
}
.see-more .button .text {
  transition: all 0.3s;
}
.see-more .button.more {
  width: 100%;
}
.see-more .spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -70%);
  -ms-transform: translate(-50%, -70%);
  transform: translate(-50%, -70%);
  opacity: 0;
}
.see-more .spinner-container .spinner {
  border-top-color: #fbfbfb;
}
.see-more .results-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0px;
  padding: 0px;
  color: #fff;
  cursor: pointer;
  opacity: 0;
}
.see-more.loading .button .text {
  opacity: 0;
}
.see-more.loading .spinner-container {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 1;
}
.see-more.error .button {
  background-color: #e74c3c;
}
.see-more.error .button .text {
  opacity: 0;
}
.see-more.error .results-text {
  opacity: 1;
}
.see-more.error:after {
  content: '\E002';
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  position: absolute;
  top: 50%;
  left: 20px;
  color: #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.see-more-container.hidden {
  display: none;
}
.hidden {
  display: none;
}
.horizontal-rule {
  width: 100%;
  border-top: 1px solid #cccccc;
  margin-bottom: 30px;
}
.vertical-rule {
  width: 0px;
  border-right: 1px solid #cccccc;
}
iframe[name="google_conversion_frame"] {
  position: fixed;
}
@media screen and (max-width: 500px) {
  .button .tooltip {
    display: none;
  }
}
select::-ms-expand {
  display: none;
}
input.image-upload {
  position: absolute;
  left: -9999px;
  z-index: -1;
}
.embed-preview-container {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  background-color: #ffffff;
}
.embed-preview-container .preview-thumbnail {
  width: 100%;
  text-align: center;
  -webkit-box-flex: 0.7;
  -moz-box-flex: 0.7;
  -webkit-flex: 0.7;
  -ms-flex: 0.7;
  flex: 0.7;
  max-height: 250px;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.embed-preview-container .preview-thumbnail img {
  max-width: 100%;
  height: auto;
}
.embed-preview-container .preview-content {
  padding: 20px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.embed-preview-container .preview-content .url-title,
.embed-preview-container .preview-content .description,
.embed-preview-container .preview-content .url {
  display: block;
}
.embed-preview-container .preview-content .url-title {
  font-size: 18px;
  font-weight: 500;
  color: #1d2129;
  line-height: 18px;
  margin-bottom: 10px;
  padding-bottom: 0;
}
.embed-preview-container .preview-content .description {
  font-size: 12px;
  line-height: 16px;
  max-height: 80px;
  overflow: hidden;
  margin-bottom: 10px;
}
.embed-preview-container .preview-content .url {
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
}
.embed-preview-container .preview-content .url a {
  color: #1d2129;
}
.embed-preview-container .clicklink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.embed-preview-container .spinner-container {
  display: none;
  text-align: center;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(0%, 50%);
  -ms-transform: translate(0%, 50%);
  transform: translate(0%, 50%);
}
.embed-preview-container.processing .spinner-container {
  display: block;
}
.embed-preview-container.has-preview {
  border-top: 1px solid #dcdcdc;
}
@media screen and (max-width: 820px) {
  .embed-preview-container {
    display: block;
  }
  .embed-preview-container .preview-thumbnail {
    height: 150px;
  }
}
.form-field {
  width: 100%;
  position: relative;
}
.form-field .field-wrapper {
  position: relative;
}
.form-field .label {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.form-field .input {
  color: #333;
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1em;
  padding: 10px 35px 9px 10px;
  outline: none;
  border: 1px solid #cdcdcd;
  border-radius: 0;
}
.form-field .input:focus {
  border-color: #6ddb2c;
}
.form-field .input:focus + .label {
  display: none;
}
.form-field .input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.form-field .input::-ms-clear {
  display: none;
}
.form-field .input::-webkit-input-placeholder {
  color: #546e7a;
  font-size: 15px;
  letter-spacing: 0.1em;
  opacity: 1;
}
.form-field .input:-moz-placeholder {
  color: #546e7a;
  font-size: 15px;
  letter-spacing: 0.1em;
  opacity: 1;
}
.form-field .input:-ms-input-placeholder {
  color: #546e7a;
  font-size: 15px;
  letter-spacing: 0.1em;
  opacity: 1;
}
.form-field .textarea {
  color: #333;
  width: 100%;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.1em;
  padding: 10px 15px 9px 10px;
  outline: none;
  border: 1px solid #cdcdcd;
  resize: none;
  min-height: 130px;
}
.form-field .spinner-container {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%) scale(0);
  -ms-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  z-index: 100;
}
.form-field .tooltip {
  position: absolute;
  bottom: 100%;
  left: 0px;
  width: 100%;
  padding: 12px 10px;
  color: #fff;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.3s;
  visibility: hidden;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.06em;
}
.form-field .tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 12px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
}
.form-field .select {
  width: 100%;
  border: 1px solid #cdcdcd;
  padding: 10px 9px;
  position: relative;
  font-size: 15px;
  letter-spacing: 0.1em;
  color: #3c4c54;
  z-index: 2;
  background: none;
  transition: all 0.3s;
  outline: none;
}
.form-field .autolist {
  width: 100%;
}
.form-field .icons-container {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #2e414a;
  transition: all 0.3s;
  z-index: 10;
  width: 40px;
  height: 100%;
}
.form-field .icons-container .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #989898;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  z-index: 99;
  font-size: 19px;
}
.form-field .icons-container .icon.valid {
  color: #2ecc71;
}
.form-field .icons-container .icon.warning {
  color: #f1c40f;
}
.form-field .icons-container .icon.error {
  color: #e74c3c;
}
.form-field .icons-container .status-icon {
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.form-field .icons-container .label-icon {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  z-index: 100;
}
.form-field .icons-container.fill .icon {
  color: #fff;
}
.form-field .icons-container.fill .spinner-container .spinner {
  border-color: #eee;
  border-top-color: #fff;
}
.form-field .icons-container.fill.green {
  background-color: #6ddb2c;
}
.form-field.has-label .icons-container {
  top: 18px;
}
.form-field .results-container {
  position: absolute;
  top: calc(100% - 2px);
  width: 100%;
  background-color: #fff;
  z-index: 101;
  box-shadow: 0px 0px 10px #aaa;
  opacity: 0;
  transition: all 0s;
  visibility: hidden;
  display: block;
}
.form-field .results-container .result {
  position: relative;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.35s;
}
.form-field .results-container .result:after {
  content: "\E147";
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) scale(0);
  -ms-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  transition: all 0.4s;
  color: #fff;
}
.form-field .results-container .result.selected,
.form-field .results-container .result:hover {
  color: #fff;
  background-color: #6ddb2c;
}
.form-field .results-container .result.selected:after,
.form-field .results-container .result:hover:after {
  -webkit-transform: translateY(-50%) scale(1);
  -ms-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}
.form-field .results-container .empty {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 20px 0px;
}
.form-field .results-container .empty .icon {
  font-size: 20px;
}
.form-field .results-container .empty .text {
  padding: 0px 0px 0px 8px;
}
.form-field .results-container.hide-empty .empty {
  display: none;
}
.form-field.has-results .input:focus + .results-container {
  opacity: 1;
  visibility: visible;
}
.form-field .selected-container {
  position: relative;
  border: 1px solid transparent;
  transition: all 0.3s;
  margin-top: 8px;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  padding: 0px 25px;
  overflow: hidden;
}
.form-field .selected-container .selected {
  opacity: 0px;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  transition: -moz-transform 0.4s, -ms-transform 0.4s, -webkit-transform 0.4s, transform 0.4s, opacity 0.4s;
  border-top: 1px solid #dcdcdc;
  padding: 10px 0px;
  position: relative;
  overflow: hidden;
  display: none;
}
.form-field .selected-container .selected .remove-selected {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #e53935;
  border-radius: 50%;
  color: #fff;
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.form-field .selected-container .selected .remove-selected .icon {
  position: absolute;
  top: 52%;
  left: 53%;
  font-size: 12px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.form-field .selected-container .selected:first-child {
  border-top: 0px;
}
.form-field .selected-container .selected.show {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.form-field .selected-container .selected.active {
  display: block;
}
.form-field .selected-container .selected.hide {
  opacity: 0;
  -webkit-transform: translateX(-50px);
  -ms-transform: translateX(-50px);
  transform: translateX(-50px);
}
.form-field .selected-container.has-selected {
  border-color: #cdcdcd;
}
.form-field .switch-box {
  display: none;
}
.form-field .switch-box:checked ~ .switch:after {
  background-color: #6ddb2c;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}
.form-field .switch {
  height: 20px;
  width: 50px;
  background-color: #ececec;
  border-radius: 9px;
  display: block;
  cursor: pointer;
}
.form-field .switch:after {
  content: '';
  width: 25px;
  height: 25px;
  background-color: #c5c5c5;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(25px, -50%);
  -ms-transform: translate(25px, -50%);
  transform: translate(25px, -50%);
  display: block;
  transition: all 0.3s;
  box-shadow: 0px 0px 3px #afafaf;
}
.form-field .switch-background {
  display: block;
  margin: 0;
}
.form-field.toggle .toggle-wrapper {
  position: relative;
  display: block;
  height: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
}
.form-field.toggle .toggle-wrapper label {
  font-weight: normal;
  cursor: pointer;
  display: block;
  text-transform: uppercase;
}
.form-field.toggle .toggle-wrapper .toggle-box {
  margin: 0 15px;
  position: relative;
  top: 2px;
}
.form-field.toggle .toggle-wrapper .toggle-box .toggle-switch {
  position: relative;
  height: 20px;
  width: 50px;
  background-color: #ececec;
  border-radius: 9px;
  display: block;
  cursor: pointer;
  z-index: 1;
}
.form-field.toggle .toggle-wrapper .toggle-box .toggle-switch:after {
  content: '';
  width: 25px;
  height: 25px;
  background-color: #6ddb2c;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  display: block;
  transition: all 0.3s;
  box-shadow: 0px 0px 3px #afafaf;
}
.form-field.toggle .toggle-wrapper .toggle-box input {
  height: 40px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -10px;
  width: 50px;
  z-index: 2;
  opacity: 0;
  zoom: 1;
  filter: alpha(opacity=0);
  cursor: pointer;
}
.form-field.toggle .toggle-wrapper .toggle-box input:checked {
  z-index: 0;
}
.form-field.toggle .toggle-wrapper .toggle-box input:checked ~ .toggle-switch:after {
  left: -1px;
  background-color: #6ddb2c;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}
.form-field.toggle .toggle-wrapper .toggle-box input ~ :checked ~ .toggle-switch:after {
  left: 25px;
  background-color: #6ddb2c;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}
.form-field.show-results .results-container {
  opacity: 1;
  visibility: visible;
}
.form-field.show-spinner .icons-container .icon {
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.form-field.show-spinner .icons-container .spinner-container {
  -webkit-transform: translateY(-50%) scale(1);
  -ms-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}
.form-field.hide-icon .icon-container .icon {
  opacity: 0;
}
.form-field.active .label {
  -webkit-transform: translateY(-50%) scale(1);
  -ms-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}
.form-field.valid .input {
  border: 1px solid #2ecc71;
}
.form-field.valid .icons-container .icon {
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.form-field.valid .icons-container .status-icon.valid {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.form-field.valid .icons-container .label-icon {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  color: #2ecc71;
}
.form-field.valid .icons-container.fill {
  background-color: #f1c40f;
}
.form-field.warning .input {
  border: 1px solid #f1c40f;
}
.form-field.warning .icons-container .icon {
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.form-field.warning .icons-container .status-icon.warning {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.form-field.warning .icons-container.fill {
  background-color: #f1c40f;
}
.form-field.warning .tooltip {
  background-color: #f1c40f;
}
.form-field.warning .tooltip:after {
  border-top-color: #f1c40f;
}
.form-field.warning:hover .tooltip {
  opacity: 1;
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  visibility: visible;
}
.form-field.error .input {
  border: 1px solid #e74c3c;
}
.form-field.error .input:focus ~ .tooltip {
  opacity: 1;
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  visibility: visible;
  z-index: 2;
}
.form-field.error .icons-container .icon {
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}
.form-field.error .icons-container .status-icon.error {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.form-field.error .icons-container .label-icon {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  color: #e74c3c;
}
.form-field.error .icons-container.fill {
  background-color: #e74c3c;
}
.form-field.error .tooltip {
  background-color: #e74c3c;
}
.form-field.error .tooltip:after {
  border-top-color: #e74c3c;
}
.form-field.error:hover .tooltip {
  opacity: 1;
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  visibility: visible;
  z-index: 2;
}
.form-field.checkbox-container .check-wrap {
  padding: 9px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
}
.form-field.checkbox-container .check-wrap .check-label {
  padding-left: 15px;
}
.form-field.checkbox-container .check-wrap .check-label label {
  font-size: 15px;
  color: #546e7a;
  line-height: 20px;
  display: inline-block;
}
.form-field.checkbox-container .check-wrap .check-field input[type="checkbox"] + label {
  display: inline-block;
  width: 17px;
  height: 17px;
  cursor: pointer;
  border: 1px solid #b7bfc3;
  background: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transition: all 0.3s;
  box-shadow: 0px 0px 3px #b7bfc3;
  position: relative;
}
.form-field.checkbox-container .check-wrap .check-field input[type="checkbox"]:checked + label {
  background: #6edb2c url(../images/check-bg.jpg) center center no-repeat;
  border: 1px solid #6edb2c;
}
.form-field.checkbox-container .check-wrap input[type=checkbox] {
  position: absolute;
  left: -9999px;
}
.form-field.checkbox-container .check-wrap.check-wrap-other .check-other {
  -webkit-box-flex: 0.9;
  -moz-box-flex: 0.9;
  -webkit-flex: 0.9;
  -ms-flex: 0.9;
  flex: 0.9;
  padding-left: 10px;
}
.form-field.checkbox-container .check-wrap.check-wrap-other .check-other .input {
  padding: 5px;
}
.form-field.radio-container .radio-wrap {
  padding: 9px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
}
.form-field.radio-container .radio-wrap .radio-label {
  padding-left: 15px;
}
.form-field.radio-container .radio-wrap .radio-label label {
  font-size: 15px;
  color: #546e7a;
  line-height: 20px;
}
.form-field.radio-container .radio-wrap .radio-field input[type="radio"] + label {
  top: -4px;
}
.form-field.radio-container .radio-wrap input[type=radio] {
  position: absolute;
  left: -9999px;
}
.form-field.hide {
  display: none;
}
form .spinner-wrap {
  position: relative;
}
form .spinner-wrap .spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) scale(0);
  -ms-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
}
form .spinner-wrap.show-spinner .spinner-container {
  -webkit-transform: translateY(-50%) scale(1);
  -ms-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}
form .spinner-wrap .spinner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: none;
}
.custom-checks-box .checks-box {
  width: 100%;
  padding: 10px 10px 10px 20px;
  outline: none;
  border: 1px solid #cdcdcd;
  max-height: 126px;
  overflow: auto;
}
.custom-checks-box .checks-box ul.checks-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.custom-checks-box .checks-box ul.checks-list li {
  padding: 9px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  align-items: center;
}
.custom-checks-box .checks-box ul.checks-list li .check-label {
  padding-left: 15px;
}
.custom-checks-box .checks-box ul.checks-list li .check-label label {
  font-size: 15px;
  color: #546e7a;
  line-height: 20px;
}
.custom-checks-box .checks-box ul.checks-list li .check-field input[type="checkbox"] + label {
  display: inline-block;
  width: 17px;
  height: 17px;
  cursor: pointer;
  border: 1px solid #b7bfc3;
  background: #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  transition: all 0.3s;
  box-shadow: 0px 0px 3px #b7bfc3;
  position: relative;
}
.custom-checks-box .checks-box ul.checks-list li .check-field input[type="checkbox"]:checked + label {
  background: #6edb2c url(../images/check-bg.jpg) center center no-repeat;
  border: 1px solid #6edb2c;
}
.custom-checks-box .checks-box ul.checks-list li input[type=checkbox] {
  position: absolute;
  left: -9999px;
}
.form-field-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.1em;
  padding: 0px 5px 5px 5px;
  display: block;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
}
.form-field-label span.tip-action {
  width: 15px;
  margin: 0 10px;
  height: 15px;
  color: #ffffff;
  background: #1b282e;
}
.form-field-label span.tip-action .tip-holder {
  width: 250px;
}
.field-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 19px;
}
.field-container.single .form-field {
  width: 100%;
}
.field-container.double .form-field {
  width: calc(50% - 9px);
}
.field-container.padded {
  padding: 18px 0px;
}
.field-container.center {
  -webkit-justify-content: center;
  justify-content: center;
}
.field-container:last-child {
  margin: 0px;
}
.form-response {
  text-align: center;
}
.form-response.error {
  color: #e74c3c;
}
.form-response.error > p {
  color: #e74c3c;
}
.form-response.success {
  color: #6ddb2c;
}
.form-response p {
  padding-bottom: 10px;
}
.form-response p:last-child {
  padding-bottom: 0px !important;
}
.form-button {
  display: inline-block;
}
.fields-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: stretch;
  align-items: stretch;
  width: 100%;
  margin-bottom: 19px;
}
.fields-container.single .form-field {
  width: 100%;
}
.fields-container.double .form-field {
  width: calc(50% - 9px);
}
.fields-container.triple .form-field {
  width: calc(33% - 9px);
}
@media screen and (max-width: 1045px) {
  .form-field-label {
    font-size: 12px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.1em;
    padding: 0px 5px 5px 5px;
    display: block;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
  }
  .form-field-label span.tip-action {
    width: 15px;
    margin: 0 10px;
    height: 15px;
    color: #ffffff;
    background: #1b282e;
  }
  .form-field-label span.tip-action .tip-holder {
    width: 250px;
  }
}
@media screen and (max-width: 670px) {
  .fields-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -moz-box;
    display: flex;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .fields-container.double .form-field {
    width: 100%;
  }
  .fields-container.double .form-field:first-child {
    margin-bottom: 19px;
  }
  .fields-container.triple .form-field {
    width: 100%;
  }
}
@media screen and (max-width: 520px) {
  .field-container.double {
    display: block;
  }
  .field-container.double .form-field {
    width: 100%;
    margin-bottom: 19px;
  }
  .field-container.center {
    text-align: center;
  }
}
@media screen and (max-width: 366px) {
  .form-field-label {
    font-size: 11px;
  }
}
.lightbox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  visibility: hidden;
}
.lightbox .overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  transition: all 0.5s 0.2s;
}
.lightbox .lightbox-content {
  position: absolute;
  top: 50px;
  left: 50%;
  -webkit-transform: translate(-50%, -30px);
  -ms-transform: translate(-50%, -30px);
  transform: translate(-50%, -30px);
  width: 100%;
  max-width: 700px;
  padding: 0 10px;
  opacity: 0;
  transition: all 0.3s;
}
.lightbox .lightbox-content .title {
  font-size: 35px;
  padding: 15px 20px;
  letter-spacing: 0.03em;
  color: #fff;
  background-color: #1b282e;
  border-bottom: 5px solid #2e444e;
  position: relative;
  font-weight: 400;
}
.lightbox .lightbox-content .title:before {
  font-family: 'FontAwesome';
  font-size: 35px;
  position: absolute;
  left: 20px;
  top: calc(50% + 4px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 0px;
}
.lightbox .lightbox-content .content {
  padding: 30px 20px 15px 20px;
  background-color: #fff;
  color: #2e414a;
}
.lightbox .lightbox-content .content h2 {
  padding-bottom: 20px;
  font-size: 28px;
}
.lightbox .lightbox-content .content h4 {
  padding-bottom: 40px;
}
.lightbox .lightbox-content .content p,
.lightbox .lightbox-content .content ul {
  padding-bottom: 15px;
}
.lightbox .lightbox-content .content .button {
  padding: 15px 40px;
}
.lightbox .lightbox-content .content .button-container {
  padding-top: 30px;
  text-align: right;
}
.lightbox .lightbox-content .content .buttons-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  padding-top: 20px;
}
.lightbox .lightbox-content .content .buttons-container .button {
  margin: 0px 2px;
}
.lightbox .lightbox-content .content .buttons-container.align-right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.lightbox .lightbox-content .content .buttons-container.centered {
  -webkit-justify-content: center;
  justify-content: center;
}
.lightbox .lightbox-image-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -55%);
  -ms-transform: translate(-50%, -55%);
  transform: translate(-50%, -55%);
  z-index: 1;
  opacity: 0;
  max-width: 80%;
  transition: all 0.3s;
}
.lightbox .lightbox-image-container .image {
  max-width: 100%;
}
.lightbox .lightbox-image-container .next,
.lightbox .lightbox-image-container .prev {
  position: absolute;
  top: 50%;
  color: #fff;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 70px;
  cursor: pointer;
  transition: all 0.2s;
}
.lightbox .lightbox-image-container .next.hide,
.lightbox .lightbox-image-container .prev.hide {
  opacity: 0;
}
.lightbox .lightbox-image-container .next:after,
.lightbox .lightbox-image-container .prev:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #353535;
  transition: all 0.4s;
  opacity: 0.8;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.lightbox .lightbox-image-container .next:hover:after,
.lightbox .lightbox-image-container .prev:hover:after {
  opacity: 1;
}
.lightbox .lightbox-image-container .next {
  right: -80px;
}
.lightbox .lightbox-image-container .prev {
  left: -80px;
}
.lightbox.show {
  visibility: visible;
}
.lightbox.show .overlay {
  opacity: 0.85;
  transition: all 0.6s;
}
.lightbox.show .lightbox-content {
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  opacity: 1;
  transition: all 0.5s 0.4s;
}
.lightbox.show .lightbox-image-container {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 1;
}
.lightbox.hide {
  display: none;
}
.lightbox.error .title {
  background-color: #e74c3c;
  border-bottom-color: #ed7669;
}
.lightbox.error .title:before {
  content: '\f06a';
}
.lightbox.success .title {
  background-color: #6ddb2c;
  border-bottom-color: #8be258;
}
.lightbox.success .title:before {
  content: '\f058';
}
.lightbox.narrow .lightbox-content {
  max-width: 500px;
}
#login-lightbox {
  color: #2e414a;
}
#login-lightbox .lightbox-content {
  max-width: 450px;
}
#post-lightbox {
  position: fixed;
}
.file-upload .file-upload-wrapper .file-uploader .file-container {
  position: relative;
  border: 1px solid #cccccc;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  cursor: pointer;
  padding: 3px 5px;
  max-width: 100%;
  width: 100%;
}
.file-upload .file-upload-wrapper .file-uploader .file-container .filename {
  font-size: 15px;
  line-height: 32px;
  letter-spacing: .1em;
  padding-bottom: 0;
  padding-left: 15px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.file-upload .file-upload-wrapper .file-uploader .file-container .action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.file-upload .file-upload-wrapper .file-uploader .file-container .action.upload .icon {
  color: black;
}
.file-upload .file-upload-wrapper .file-uploader .file-container .action.delete .icon {
  color: #e74c3c;
}
.file-upload .file-upload-wrapper .file-upload-field.hide {
  display: none;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(material-icons/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(material-icons/MaterialIcons-Regular.woff2) format('woff2'), url(material-icons/MaterialIcons-Regular.woff) format('woff'), url(material-icons/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
.row table.sort thead th.sortable {
  cursor: pointer;
}
.row table.sort thead th.sortable::after {
  content: "\f0dc";
  font-family: Font Awesome\ 5 Free;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-weight: 900;
  padding-left: 5px;
}
.row #memberships-totals .animation-wrapper {
  display: block !important;
}
.row #memberships-totals p {
  padding: 5px 0;
}
/** Cookie popup **/
.ckpopup-block .ckpopup-container {
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 15px 30px;
  font-size: 12px;
  line-height: 1.2em;
  text-align: center;
  display: none;
  z-index: 99999;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.ckpopup-block .ckpopup-container .ckpopup-close {
  font-size: 16px;
  font-weight: 100;
  line-height: 1;
  color: white;
  filter: alpha(opacity=20);
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 5px 10px;
}
.ckpopup-block .ckpopup-container .ckpopup-close .fa {
  color: white;
}
.ckpopup-block .ckpopup-container .ckpopup-text {
  font-size: 12px;
  color: white;
}
.ckpopup-block .ckpopup-container .ckpopup-text .ckpopup-button {
  color: white;
  text-decoration: none;
  border-bottom: 2px solid white;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.ckpopup-block .ckpopup-container .ckpopup-text .ckpopup-button:hover {
  border-bottom-color: transparent;
}
