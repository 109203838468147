@form-text-color: #546e7a;

select{
	&::-ms-expand{
		display: none;
	}
}

input.image-upload{
    position: absolute;
    left: -9999px;
    z-index: -1;
}
& .embed-preview-container{
	width: 100%;
	position: relative;
	.flex-container();
	background-color: #ffffff;
	& .preview-thumbnail{
		width: 100%;
		text-align: center;
		.flex(0.7);
		max-height: 250px;
		overflow: hidden;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat:no-repeat;
		& img{
			max-width: 100%;
			height: auto;
		}
	}
	& .preview-content{
		padding: 20px;
		.flex(1);
		& .url-title,
		& .description,
		& .url{
			display: block;
		}
		& .url-title{
			font-size: 18px;
			font-weight: 500;
			color: #1d2129;
			line-height: 18px;
			margin-bottom: 10px;
			padding-bottom: 0;
		}
		& .description{
			font-size: 12px;
			line-height: 16px;
			max-height: 80px;
			overflow: hidden;
			margin-bottom: 10px;
		}
		& .url{
			font-size: 11px;
			line-height: 14px;
			text-transform: uppercase;
			& a{
				color: #1d2129;
			}
		}
	}
	& .clicklink{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	& .spinner-container{
		display: none;
		text-align: center;
		position: absolute;
		top: 0;
		left: 50%;
		.translate(0%, 50%);
	}
	&.processing{
		& .spinner-container{
			display: block;
		}
	}
	&.has-preview{
		border-top: 1px solid #dcdcdc;
	}
	@media screen and (max-width: 820px) {
		display: block;
		.preview-thumbnail{
			height: 150px;
		}
	}
}
.form-field{
	width: 100%;
	position: relative;

	& .field-wrapper{
		position: relative;
	}
	& .label{
		position: absolute;
		top: 50%;
		left: 10px;
		.translateY(-50%);
	}
	& .input{
		color: #333;
		width: 100%;
		outline: none;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.1em;
		padding: 10px 35px 9px 10px;
		outline: none;
		border: 1px solid #cdcdcd;
        border-radius: 0;

		&:focus{
			border-color: @brand-green;

			& + .label{
				display: none;
			}
		}
		&:-webkit-autofill{
			-webkit-box-shadow: 0 0 0 1000px white inset !important;
		}
		&::-ms-clear{
			display: none;
		}
		.placeholder({
			color: @form-text-color;
			font-size: 15px;
			letter-spacing: 0.1em;
			opacity: 1;
		});
	}
	& .textarea{
		color: #333;
		width: 100%;
		outline: none;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.1em;
		padding: 10px 15px 9px 10px;
		outline: none;
		border: 1px solid #cdcdcd;
		resize: none;
		min-height: 130px;
	}
	& .spinner-container{
		position: absolute;
		top: 50%;
		right: 10px;
		.transform(translateY(-50%) scale(0));
		z-index: 100;
	}
	& .tooltip{
		position: absolute;
		bottom: 100%;
		left: 0px;
		width: 100%;
		//background-color: @nice-red;
		padding: 12px 10px;
		color: #fff;
		.translateY(20px);
		opacity: 0;
		transition: all 0.3s;
		visibility: hidden;
		font-size: 15px;
		line-height: 18px;
		letter-spacing: 0.06em;
		&:after{
			content: "";
			position: absolute;
			top: 100%;
			right: 12px;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			//border-top: 10px solid @nice-red;
			border-top: 10px solid transparent;
		}
	}
	& .select{
		width: 100%;
		border: 1px solid #cdcdcd;
		padding: 10px 9px;
		position: relative;
		font-size: 15px;
		letter-spacing: 0.1em;
		color: #3c4c54;
		z-index: 2;
		background: none;
		transition: all 0.3s;
		outline: none;
	}
	& .autolist{
		width: 100%;
	}
	& .icons-container{
		position: absolute;
		top: 0px;
		right: 0px;
		color: @brand-light-navy;
		transition: all 0.3s;
		z-index: 10;
		width: 40px;
		height: 100%;
		//color: #fff;

		& .icon{
			position: absolute;
			top: 50%;
			left: 50%;
			color: #989898;
			.translate(-50%, -50%);
			transition: all 0.3s;
			z-index: 99;
			font-size: 19px;

			&.valid{
				color: @nice-green;
			}
			&.warning{
				color: @nice-yellow;
			}
			&.error{
				color: @nice-red;
			}
		}
		& .status-icon{
			.transform(translate(-50%, -50%) scale(0));
		}
        & .label-icon{
            .transform(translate(-50%, -50%) scale(1));
            z-index: 100;
        }
		&.fill{
			& .icon{
				color: #fff;
			}
			& .spinner-container{
				& .spinner{
					border-color: #eee;
					border-top-color: #fff;
				}
			}
			&.green{
				background-color: @brand-green;
			}
		}
	}
    &.has-label{
        & .icons-container{
            top: 18px;
        }
    }
	& .results-container{
		position: absolute;
		top: calc(~"100% - 2px");
		width: 100%;
		background-color: #fff;
		z-index: 101;
		box-shadow: 0px 0px 10px #aaa;
		opacity: 0;
		transition: all 0s;
		visibility: hidden;
		display: block;

		& .result{
			position: relative;
			padding: 10px 15px;
			cursor: pointer;
			transition: all 0.35s;

			&:after{
				content: "\E147";
				.material-icon(20px);
				position: absolute;
				top: 50%;
				right: 20px;
				.transform(translateY(-50%) scale(0));
				transition: all 0.4s;
				color: #fff;
			}
			&.selected,
			&:hover{
				color: #fff;
				background-color: @brand-green;

				&:after{
					.transform(translateY(-50%) scale(1));
				}
			}
		}
		& .empty{
			.flex-container();
			.align-items(center);
			.justify-content(center);
			padding: 20px 0px;

			& .icon{
				font-size: 20px;
			}
			& .text{
				padding: 0px 0px 0px 8px;
			}
		}
		&.hide-empty{
			& .empty{
				display: none;
			}
		}
	}
	&.has-results{
		& .input:focus + .results-container{
			opacity: 1;
			visibility: visible;
		}
	}
	& .selected-container{
		position: relative;
		border: 1px solid transparent;
		transition: all 0.3s;
		margin-top: 8px;
		.transform(translate3d(0,0,0));
		padding: 0px 25px;
		overflow: hidden;

		& .selected{
			opacity: 0px;
			.translateY(50px);
			transition: -moz-transform 0.4s, -ms-transform 0.4s, -webkit-transform 0.4s, transform 0.4s, opacity 0.4s;
			border-top: 1px solid @default-border-color;
			padding: 10px 0px;
			position: relative;
			overflow: hidden;
			display: none;

			& .remove-selected{
				position: absolute;
				top: 50%;
				right: 20px;
				.translateY(-50%);
				background-color: #e53935;
				border-radius: 50%;
				color: #fff;
				width: 17px;
				height: 17px;
				cursor: pointer;

				& .icon{
					position: absolute;
					top: 52%;
					left: 53%;
					font-size: 12px;
					.translate(-50%, -50%);
				}
			}
			&:first-child{
				border-top: 0px;
			}
			&.show{
				opacity: 1;
				.translateY(0px);
			}
            &.active{
                display: block;
            }
			&.hide{
				opacity: 0;
				.translateX(-50px);
			}
		}
		&.has-selected{
			border-color: #cdcdcd;
		}
	}
	& .switch-box{
		display: none;

		&:checked{
			& ~ .switch{
				&:after{
					background-color: @brand-green;
					.translate(0px, -50%);
				}
			}
		}
	}
	& .switch{
		height: 20px;
		width: 50px;
		background-color: #ececec;
		border-radius: 9px;
		display: block;
		cursor: pointer;

		&:after {
			content: '';
			width: 25px;
			height: 25px;
			background-color: #c5c5c5;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 0;
			.translate(25px, -50%);
			display: block;
			transition: all 0.3s;
			box-shadow: 0px 0px 3px #afafaf;
		}
	}
	& .switch-background{
        display: block;
        margin: 0;
	}
    &.toggle{
        & .toggle-wrapper{
            position: relative;
            display: block;
            height: 30px;
            .flex-container();
            .align-items(center);
            & label{
                font-weight: normal;
                cursor: pointer;
                display: block;
                text-transform: uppercase;
            }
            & .toggle-box{
                margin: 0 15px;
                position: relative;
                top: 2px;
                & .toggle-switch{
                    position: relative;
                    height: 20px;
                    width: 50px;
                    background-color: #ececec;
                    border-radius: 9px;
                    display: block;
                    cursor: pointer;
                    z-index: 1;
                    &:after {
                        content: '';
                        width: 25px;
                        height: 25px;
                        background-color: @brand-green;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        .translate(0px, -50%); // Default to first label
                        display: block;
                        transition: all 0.3s;
                        box-shadow: 0px 0px 3px #afafaf;
                    }
                }
                & input{
                    height: 40px;
                    left: 0;
                    margin: 0;
                    padding: 0;
                    position: absolute;
                    top: -10px;
                    width: 50px;
                    z-index: 2;
                    opacity:0;
                    zoom: 1;
                    filter: alpha( opacity=0 );
                    cursor: pointer;
                    &:checked{
                        z-index: 0;
                        & ~ .toggle-switch{

                            &:after{
                                left: -1px;
                                background-color: @brand-green;
                                .translate(0px, -50%);
                            }
                        }
                    }
                    &~:checked{
                        & ~ .toggle-switch{
                            &:after{
                                left: 25px;
                                background-color: @brand-green;
                                .translate(0px, -50%);
                            }

                        }
                    }
                }
            }


            //& .toggle-input{
            //    position: absolute;
            //    z-index: 2; /*  input to be over the .switch*/
            //    left: -9999px;

            //}
            //& input:focus + label{
            //
            //}
        }
    }
	&.show-results{
		& .results-container{
			opacity: 1;
			visibility: visible;
		}
	}
	&.show-spinner{
		& .icons-container {
			& .icon{
				.transform(translate(-50%, -50%) scale(0));
			}
			& .spinner-container {
				.transform(translateY(-50%) scale(1));
			}
		}
	}
	&.hide-icon{
		& .icon-container{
			& .icon {
				opacity: 0;
			}
		}
	}
	&.active{
		& .label{
			.transform(translateY(-50%) scale(1));
		}
	}
	&.valid{
		& .input{
			border: 1px solid @nice-green;
		}
		& .icons-container{
			& .icon{
				.transform(translate(-50%, -50%) scale(0));
			}
			& .status-icon{
				&.valid{
					.transform(translate(-50%, -50%) scale(1));
				}
			}
            & .label-icon{
                .transform(translate(-50%, -50%) scale(1));
                color: @nice-green;
            }
			&.fill {
				background-color: @nice-yellow;
			}
		}
	}
	&.warning{
		& .input{
			border: 1px solid @nice-yellow;
		}
		& .icons-container{
			& .icon{
				.transform(translate(-50%, -50%) scale(0));
			}
			& .status-icon{
				&.warning{
					.transform(translate(-50%, -50%) scale(1));
				}
			}
			&.fill {
				background-color: @nice-yellow;
			}
		}
		& .tooltip{
			background-color: @nice-yellow;

			&:after{
				border-top-color: @nice-yellow;
			}
		}
		&:hover{
			& .tooltip{
				opacity: 1;
				.translateY(-5px);
				visibility: visible;
			}
		}
	}
	&.error{
		& .input{
			border: 1px solid @nice-red;

			&:focus{
				& ~ .tooltip{
					opacity: 1;
					.translateY(-5px);
					visibility: visible;
                    z-index: 2;
				}
			}
		}
		& .icons-container{
			& .icon{
				.transform(translate(-50%, -50%) scale(0));
			}
			& .status-icon{
				&.error{
					.transform(translate(-50%, -50%) scale(1));
				}
			}
            & .label-icon{
                .transform(translate(-50%, -50%) scale(1));
                color: @nice-red;
            }
			&.fill {
				background-color: @nice-red;
			}
		}
		& .tooltip{
			background-color: @nice-red;

			&:after{
				border-top-color: @nice-red;
			}
		}
		&:hover{
			& .tooltip{
				opacity: 1;
				.translateY(-5px);
				visibility: visible;
                z-index: 2;
			}
		}
	}
    &.checkbox-container{
        & .check-wrap{
            padding: 9px 0;
            .flex-container();
            .justify-content(flex-start);
            .align-items(center);
            & .check-label{
                padding-left: 15px;
                & label{
                    font-size: 15px;
                    color: #546e7a;
                    line-height: 20px;
                    display: inline-block;
                }
            }
            & .check-field{
                & input[type="checkbox"] + label{
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    cursor: pointer;
                    border: 1px solid #b7bfc3;
                    background: #ffffff;
                    -webkit-border-radius:3px;
                    -moz-border-radius:3px;
                    border-radius:3px;
                    transition: all 0.3s;
                    box-shadow: 0px 0px 3px #b7bfc3;
                    position: relative;
                }
                & input[type="checkbox"]:checked + label{
                    background: #6edb2c url(../images/check-bg.jpg) center center no-repeat;
                    border: 1px solid #6edb2c;
                }
            }
            & input[type=checkbox]{
                position: absolute;
                left: -9999px;
            }
            &.check-wrap-other{
                & .check-other{
                    .flex(0.9);
                    padding-left: 10px;
                    & .input{
                        padding: 5px;
                    }
                }
            }
        }
    }
    &.radio-container{
        & .radio-wrap{
            padding: 9px 0;
            .flex-container();
            .justify-content(flex-start);
            .align-items(center);
            & .radio-label{
                padding-left: 15px;
                & label{
                    font-size: 15px;
                    color: #546e7a;
                    line-height: 20px;
                }
            }
            & .radio-field{
				& input[type="radio"] + label{
                  top: -4px;
				}
                & input[type="radio"] + label::before{
                    //content:"";
                    //display: inline-block;
                    //width: 15px;
                    //height: 15px;
                    //cursor: pointer;
                    //line-height: 10px;
                    //font-size: 28px;
                    //color: #ffffff;
                    //padding: 2px;
                    //border: 2px solid #b7bfc3;
                    //-webkit-border-radius:20px;
                    //-moz-border-radius:20px;
                    //border-radius:20px;
                    //transition: all 0.3s;
                    //box-shadow: 0px 0px 2px #ffffff inset;
                    //position: relative;
                }
                & input[type="radio"]:checked + label::before{
                    //content:"\2022";
                    //border-color: #6edb2c;
                    //text-align: center;
                    //color: #6edb2c;
                    //border: 2px solid #ffffff;
                    //box-shadow: 0px 0px 2px #6edb2c;
                }
            }
            & input[type=radio]{
                position: absolute;
                left: -9999px;
            }
        }
    }
	&.hide{
		display: none;
	}
}
form{
    & .spinner-wrap{
        position: relative;
        & .spinner-container{
            position: absolute;
            top: 50%;
            left: 50%;
            .transform(translateY(-50%) scale(0));
        }
        &.show-spinner{
            & .spinner-container {
                .transform(translateY(-50%) scale(1));
            }
        }
        & .spinner-overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
            display: none;
        }
    }
}
& .custom-checks-box{
	& .checks-box{
		width: 100%;
		padding: 10px 10px 10px 20px;
		outline: none;
		border: 1px solid #cdcdcd;
		max-height: 126px;
		overflow: auto;
		& ul.checks-list{
			margin: 0;
			padding: 0;
			list-style: none;
			& li{
				padding: 9px 0;
				.flex-container();
				.justify-content(flex-start);
                .align-items(center);
				& .check-label{
					padding-left: 15px;
					& label{
						font-size: 15px;
						color: #546e7a;
						line-height: 20px;
					}
				}
				& .check-field{
					& input[type="checkbox"] + label{
						display: inline-block;
						width: 17px;
						height: 17px;
						cursor: pointer;
						border: 1px solid #b7bfc3;
						background: #ffffff;
						-webkit-border-radius:3px;
						-moz-border-radius:3px;
						border-radius:3px;
						transition: all 0.3s;
						box-shadow: 0px 0px 3px #b7bfc3;
                        position: relative;
					}
					& input[type="checkbox"]:checked + label{
						background: #6edb2c url(../images/check-bg.jpg) center center no-repeat;
						border: 1px solid #6edb2c;
					}
				}
				& input[type=checkbox]{
					position: absolute;
					left: -9999px;
				}
			}
		}
	}
}
.form-field-label{
	font-size: 14px;
	font-weight: 700;
	line-height: 32px;
	letter-spacing: 0.1em;
	padding: 0px 5px 5px 5px;
	display: block;
	cursor: pointer;
    position: relative;
    text-transform: uppercase;

    & span.tip-action{
		width: 15px;
		margin: 0 10px;
		height: 15px;
		color: #ffffff;
		background: #1b282e;

		& .tip-holder{
			width: 250px;
		}
    }

}
.field-container{
	.flex-container();
	.justify-content(space-between);
	width: 100%;
	margin-bottom: 19px;

	&.single{
		& .form-field{
			width: 100%;
		}
	}
	&.double{
		& .form-field{
			width: calc(~"50% - 9px");
		}
	}
	&.padded{
		padding: 18px 0px;
	}
	&.center{
		.justify-content(center);
	}
	&:last-child{
		margin: 0px;
	}
}
.form-response{
	text-align: center;

	&.error{
		color: @nice-red;
        & > p{
            color: @nice-red;
        }
	}
	&.success{
		color: @brand-green;
	}
	& p{
		padding-bottom: 10px;
		&:last-child{
			padding-bottom: 0px !important;
		}
	}
}
.form-button{
	display: inline-block;
}

.fields-container{
	.flex-container();
	.justify-content(space-between);
	.align-items(stretch);
	width: 100%;
	margin-bottom: 19px;

	&.single{
		& .form-field{
			width: 100%;
		}
	}
	&.double{
		& .form-field{
			width: calc(~"50% - 9px");
		}
	}
	&.triple{
		& .form-field{
			width: calc(~"33% - 9px");
		}
	}
}

@media screen and (max-width: 1045px) {
    .form-field-label{
        font-size: 12px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.1em;
        padding: 0px 5px 5px 5px;
        display: block;
        cursor: pointer;
        position: relative;
        text-transform: uppercase;

        & span.tip-action{
            width: 15px;
            margin: 0 10px;
            height: 15px;
            color: #ffffff;
            background: #1b282e;

            & .tip-holder{
                width: 250px;
            }
        }

    }
}

@media screen and (max-width: 670px) {
    .fields-container {
        .flex-container(row, wrap);
        &.double {
            & .form-field {
                width: 100%;
                &:first-child {
                    margin-bottom: 19px;
                }
            }
        }
        &.triple {
            & .form-field {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 520px){
	.field-container{
		&.double{
			display: block;

			& .form-field{
				width: 100%;
				margin-bottom: 19px;
			}
		}
		&.center{
			text-align: center;
		}
	}
}
@media screen and (max-width: 366px) {
    .form-field-label {
        font-size: 11px;
    }
}
