.lightbox{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 1000;
	visibility: hidden;

	& .overlay{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: #000;
		opacity: 0;
		transition: all 0.5s 0.2s;
	}
	& .lightbox-content{
		position: absolute;
        top: 50px;
		left: 50%;
		.translate(-50%, -30px);
		width: 100%;
		max-width: 700px;
        padding: 0 10px;
		opacity: 0;
		transition: all 0.3s;

		& .title{
			font-size: 35px;
			padding: 15px 20px;
			letter-spacing: 0.03em;
			color: #fff;
			background-color: @brand-navy;
			border-bottom: 5px solid lighten(@brand-navy, 10%);
			position: relative;
			font-weight: 400;

			&:before{
				font-family: 'FontAwesome';
				font-size: 35px;
				position: absolute;
				left: 20px;
				top: calc(~"50% + 4px");
				.translateY(-50%);
				line-height: 0px;
			}
		}
		& .content {
			padding: 30px 20px 15px 20px;
			background-color: #fff;
			color: @brand-light-navy;

			& h2{
				padding-bottom: 20px;
				font-size: 28px;
			}
			& h4{
				padding-bottom: 40px;
			}
			& p,
			& ul{
				padding-bottom: 15px;
			}
			& .button{
				padding: 15px 40px;
			}
			& .button-container{
				padding-top: 30px;
				text-align: right;
			}
			& .buttons-container{
				.flex-container();
				padding-top: 20px;
				& .button{
					margin: 0px 2px;
				}
				&.align-right{
					.justify-content(flex-end);
				}
				&.centered{
					.justify-content(center);
				}
			}
		}
	}
	& .lightbox-image-container{
		position: absolute;
		top: 50%;
		left: 50%;
		.translate(-50%, -55%);
		z-index: 1;
		opacity: 0;
		max-width: 80%;
		transition: all 0.3s;

		& .image{
			max-width: 100%;
		}
		& .next,
		& .prev{
			position: absolute;
			top: 50%;
			color: #fff;
			.translateY(-50%);
			font-size: 70px;
			cursor: pointer;
			transition: all 0.2s;

			&.hide{
				opacity: 0;
			}
			&:after{
				content: '';
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				z-index: -1;
				background-color: #353535;
				transition: all 0.4s;
				opacity: 0.8;
				.backface-visibility(hidden);
			}
			&:hover{
				&:after{
					opacity: 1;
				}
			}
		}
		& .next{
			right: -80px;
		}
		& .prev{
			left: -80px;
		}
	}
	&.show{
		visibility: visible;

		& .overlay{
			opacity: 0.85;
			transition: all 0.6s;
		}
		& .lightbox-content{
			.translate(-50%, 0px);
			opacity: 1;
			transition: all 0.5s 0.4s;
		}
		& .lightbox-image-container{
			.translate(-50%, -50%);
			opacity: 1;
		}
	}
	&.hide{
		display: none;
	}
	&.error{
		& .title{
			background-color: @nice-red;
			border-bottom-color: lighten(@nice-red, 10%);

			&:before{
				content: '\f06a';
			}
		}
	}
	&.success{
		& .title{
			background-color: @brand-green;
			border-bottom-color: lighten(@brand-green, 10%);

			&:before{
				content: '\f058';
			}
		}
	}
	&.narrow{
		& .lightbox-content {
			max-width: 500px;
		}
	}
}

#login-lightbox{
	color: @brand-light-navy;
    & .lightbox-content {
        max-width: 450px;
    }
}

#post-lightbox {
    position: fixed;
}
